<template>

    <div>
        <v-dialog v-model="dialog" max-width="720" transition="dialog-bottom-transition">
            <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn color="secondaryAccent" class="secondary" outlined v-bind="attrs" v-on="on"
                    @click="btnDialogClicked">
                    New experience
                </v-btn>
            </template> -->
            <div class="bg-white relative">
                <div class="flex items-start justify-between p-4">
                    <h3 class="text-xl font-semibold">
                        New experience
                    </h3>
                    <button type="button"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                        data-modal-toggle="product-modal">
                        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </button>
                </div>
                <div class="p-6 space-y-6">
                    <form action="#">
                        <div class="grid grid-cols-6 gap-6">
                            <div class="col-span-6 sm:col-span-6">
                                <label for="prosition name"
                                    class="text-sm font-medium text-gray-900 block mb-2">Position
                                    title</label>
                                <input type="text" v-model="experience.title" label="Position name"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                                    placeholder="Position name" required="">
                            </div>
                            <div class="col-span-6 sm:col-span-6">
                                <label for="brand"
                                    class="text-sm font-medium text-gray-900 block mb-2">Enterprise</label>
                                <input type="text" v-model="company_search.keywords"
                                    value="company_search.keywords.name" label="Enterprise"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                                    placeholder="Enterprise" required="">
                                <div class="input-search-float-relative" ref="clickOutSideSearch">
                                    <div v-if="company_search.keywords && (company_search.visibility == true)"
                                        class="input-search-float-item">
                                        <span class="item" v-for="(fruit, ids) in filteredCompany()" :key="ids"
                                            @click="company_search.keywords = fruit.name, company_search.visibility = false">
                                            {{ fruit.name }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-span-6 sm:col-span-3">
                                <label for="other enterprise" class="text-sm font-medium text-gray-900 block mb-2">Other
                                    enterprise</label>
                                <input type="text" v-model="experience.enterprise_name" label="Other enterprise"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                                    placeholder="Other enterprise" required=""
                                    :disabled="company_search.keywords !== null ? true : false">
                            </div> -->
                            <div class="col-span-6 sm:col-span-3">
                                <label for="startdate" class="text-sm font-medium text-gray-900 block mb-2">Started
                                    at</label>
                                <input type="date" v-model="experience.started_at" label="Start date" neme="startdate"
                                    id="startdate"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                                    placeholder="" required="">
                            </div>

                            <div class="col-span-6 sm:col-span-3">
                                <label for="enddate" class="text-sm font-medium text-gray-900 block mb-2">End at</label>
                                <input type="date" v-model="experience.end_at" label="End date" neme="enddate"
                                    id="enddate"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                                    placeholder="" required="">
                            </div>
                            <div class="col-span-full">
                                <label for="product-details"
                                    class="text-sm font-medium text-gray-900 block mb-2">Descibe
                                    your
                                    activities
                                </label>
                                <textarea v-model="experience.activity" label="About me" rows="6"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-4"
                                    placeholder="Aboute me"></textarea>
                            </div>

                            <div class="col-span-full">
                                <label for="comment" class="text-sm font-medium text-gray-900 block mb-2">Comment about
                                    experience
                                </label>
                                <textarea v-model="experience.comment" label="comment about experience" rows="6"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-4"
                                    placeholder="Others comments"></textarea>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="p-6 border-t border-gray-200 rounded-b">
                    <v-btn color="error" outlined class="mt-4 mr-4" type="reset" @click.prevent="resetForm">
                        Unsave
                    </v-btn>
                    <v-btn color="primary" class="mt-4" :loading="loading ? true : false"
                        @click.prevent="saveExperience">
                        Validate update
                    </v-btn>
                </div>
            </div>
        </v-dialog>

        <div class="bg-white rounded-lg mt-10">
            <div class="py-4 flex items-start justify-between border-t cursor-pointer">
                <div></div>
                <!-- <h2 class="section-title text-xl mb-8">Experience</h2> -->
                <button
                    class="h-8 px-3 text-md font-bold text-blue-400 border border-blue-400 rounded-full hover:bg-blue-100"
                    @click="btnDialogClicked">Add experience</button>
            </div>
            <div class="space-y-4">
                <div v-for="(item, ids) in userexperience" :key="ids">
                    <div class="py-4 flex items-start justify-between border-t">
                        <div class="flex items-start">
                            <img class="h-10 w-10" :src="require('@/assets/images/avatars/profil.jpg')">
                            <div class="ml-2 flex flex-col">
                                <div class="leading-snug text-md text-gray-900 font-bold">{{ item.title }}</div>
                                <div class="leading-snug text-md text-gray-600">{{
                                    item.enterprise.id !== null ?
                                        item.enterprise.name :
                                        item.otherenterprise }}</div>
                                <div class="leading-snug text-xs text-gray-600">{{ item.startdate }} - {{
                                    item.enddate ? item.enddate : "Aujourd'hui" }}</div>
                                <div class="leading-snug text-md mt-2 text-gray-900">{{ item.comment }}</div>
                            </div>
                        </div>
                        <v-btn text small rounded-lg color="secondary" class="px-3 text-md border subsecondary"
                            @click="editExperience(item)">Edit</v-btn>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
    inject: {
        theme: {
            default: { isDark: false },
        },
    },
    data() {
        return {
            loading: false,
            about: '',
            oabout: '',
            dialog: false,
            experience: {
                title: '',
                enterprise: null,
                enterprise_name: '',
                started_at: '',
                end_at: '',
                activity: '',
                comment: '',
                edit: false,
                keys: null
            },
            company_search: {
                datas: [],
                keywords: "",
                visibility: true
            },
            userexperience: []
        }
    },
    methods: {
        btnDialogClicked() {
            this.experience.keys = null
            this.experience.edit = false
            this.dialog = !this.dialog
            this.experience.title = ''
            this.experience.enterprise_name = ''
            this.experience.started_at = ''
            this.experience.end_at = ''
            this.experience.activity = ''
            this.experience.comment = ''
        },
        resetForm() {
            this.about = this.oabout
        },
        editExperience(value) {
            this.experience.keys = value.id
            this.experience.edit = true
            this.dialog = !this.dialog

            this.experience.title = value.title
            this.experience.enterprise_name = value.enterprise.name
            this.experience.started_at = value.startdate
            this.experience.end_at = value.enddate
            this.experience.activity = value.activity
            this.experience.comment = value.comment
        },
        saveExperience() {
            this.loading = false;

            var reqaction = 'experience.create'
            // var tmp_enterprise = this.company_search.datas.filter((item) => item.name.toLowerCase() == this.company_search.keywords.toLowerCase())
            var bodyFormData = {
                experience: {
                    "user.id": this.$store.getters.getUser.id,
                    title: this.experience.title,
                    "enterprise.id": null,
                    otherenterprise: this.experience.enterprise_name,
                    startdate: this.experience.started_at,
                    enddate: this.experience.end_at,
                    activity: this.experience.activity,
                    comment: this.experience.comment,
                }
            }
            if (this.experience.edit === true) {
                reqaction = `experience.update?dfilter=on&id:eq=${this.experience.keys}`
                var bodyFormData = {
                experience: {
                    id: this.experience.id,
                    "user.id": this.$store.getters.getUser.id,
                    title: this.experience.title,
                    "enterprise.id": null,
                    otherenterprise: this.experience.enterprise_name,
                    startdate: this.experience.started_at,
                    enddate: this.experience.end_at,
                    activity: this.experience.activity,
                    comment: this.experience.comment,
                }
            }
            }
            this.loading = true;
            Drequest.api(reqaction)
                .data(bodyFormData)
                .raw((response) => {
                    switch (response.success) {
                        case true:
                            this.loading = false;
                            // this.$router.push({ name: 'user.account' });
                            this.experience.edit = false
                            this.fetchExperience()
                            this.dialog = !this.dialog

                            break;
                        default:
                            this.loading = false;
                            this.experience.edit = false
                            break;
                    }
                }).catch((err) => {
                    this.loading = false;
                    this.experience.edit = false
                });
        },
        filteredCompany() {
            if (this.company_search.keywords.length > 0) {
                this.company_search.visibility = true
                return this.company_search.datas.filter((item) => item.name.toLowerCase().includes(this.company_search.keywords.toLowerCase()))
            }
            else {
                this.experience.enterprise_name = this.company_search.keywords
                this.company_search.visibility = false
                return []
            }
        },
        fetchExperience(){
            
        Drequest.api(`lazyloading.experience?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
            if (response.success === true) {
                this.userexperience = response.listEntity
            }
        }).catch((err) => { });
        }
    },
    beforeMount() {
        Drequest.api(`lazyloading.user?dfilters=on&id:eq=${this.$store.getters.getUser.id}`).get((response) => {
            if (response.success === true) {
                this.about = response.listEntity[0].about
                this.oabout = this.about
            }
        }).catch((err) => { });
this.fetchExperience()
    },
    mounted() {
        Drequest.api(`lazyloading.enterprise`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((element, index) => {
                        this.company_search.datas.push({
                            name: element.name,
                            token: element.id
                        })

                    });
                }
            })
            .catch((err) => {
            });
    }
}
</script>