<template>
  <div>


    <div class="bg-white relative">
      <!-- <div class="flex items-start justify-between p-4 border-b">
        <h3 class="text-xl font-semibold">
          Personnals Informations
        </h3>
        <button type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          data-modal-toggle="product-modal">
          <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </div> -->
      <div class="p-6 space-y-6">
        <form action="#">
          <div class="grid grid-cols-6 gap-6">
            <div class="col-span-6 sm:col-span-3">
              <label for="product-name" class="text-sm font-medium text-gray-900 block mb-2">First Name</label>
              <input type="text" v-model="firstname" label="First Name"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                placeholder="First Name" required="">
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label for="category" class="text-sm font-medium text-gray-900 block mb-2">Last Name</label>
              <input type="text" v-model="lastname" label="Last Name"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                placeholder="Last Name" required="">
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label for="brand" class="text-sm font-medium text-gray-900 block mb-2">E-mail</label>
              <input type="text" v-model="email" label="E-mail"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                placeholder="E-mail" required="">
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label for="price" class="text-sm font-medium text-gray-900 block mb-2">Phone number</label>
              <input type="number" v-model="phonenumber" label="Phone number"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                placeholder="Phone number" required="">
            </div>
            <div class="col-span-full">
              <label for="product-details" class="text-sm font-medium text-gray-900 block mb-2">Aboute me</label>
              <textarea v-model="about" label="About me" rows="6"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-4"
                placeholder="Aboute me"></textarea>
            </div>
          </div>
        </form>
      </div>

      <div class="p-6 border-t border-gray-200 rounded-b space-y-6">
        <form>
          <div class="grid grid-cols-6 gap-6">
            <div class="col-span-full">
              <label for="product-name" class="text-sm font-medium text-gray-900 block mb-2">Current position</label>
              <input type="text" v-model="currentposition" label="Current position<"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                placeholder="Current position<" required="">
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label for="brand" class="text-sm font-medium text-gray-900 block mb-2">Current company</label>
              <input type="text" v-model="currentcompany" label="Current company"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                placeholder="Current company" required="">
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label for="category" class="text-sm font-medium text-gray-900 block mb-2">Year of experience</label>
              <input type="number" v-model="yearexperience" label="Year of experience"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                placeholder="Year of experience" required="">
            </div>
          </div>
        </form>
      </div>

      <div class="p-6 border-t border-gray-200 rounded-b">
        <v-btn color="error" outlined class="mr-4" type="reset" @click.prevent="resetForm">
          Unsave
        </v-btn>
        <v-btn color="primary" :loading="loading ? true : false" @click.prevent="updateBasicUserInformations">
          Validate update
        </v-btn>
      </div>

    </div>


    <!-- <v-form class="my-4">
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="firstname" :value="this.firstname" label="First Name" dense outlined hide-details>
          </v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field v-model="lastname" :value="this.lastname" label="Last Name" dense outlined hide-details>
          </v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field v-model="address" :value="this.address" label="Adress" dense outlined hide-details>
          </v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field v-model="email" :value="this.email" label="E-mail" dense outlined hide-details>
          </v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field v-model="phonenumber" :value="this.phonenumber" label="Phone number" dense outlined
            hide-details>
          </v-text-field>
        </v-col>

        <v-col cols="12">
          <v-btn color="error" outlined class="mt-4 mr-4" type="reset" @click.prevent="resetForm">
            Unsave
          </v-btn>
          <v-btn color="primary" class="mt-4" :loading="loading ? true : false"
            @click.prevent="updateBasicUserInformations">
            Validate update
          </v-btn>
        </v-col>
      </v-row>
    </v-form> -->
  </div>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
  data() {
    return {
      loading: false,
      user: {},
      firstname: this.firstname,
      lastname: this.lastname,
      username: this.username,
      // birthdate: 'this.birthdate',
      address: this.address,
      phonenumber: this.phonenumber,
      email: this.email,
      about: this.about,
      currentcompany: '',
      currentposition: '',
      yearexperience: '',
    }
  },
  methods: {
    updateBasicUserInformations() {

      this.$emit('updateBasicInfos', {
        'user': {
          'firstname': this.firstname,
          'lastname': this.lastname,
          'email': this.email,
          'phonenumber': this.phonenumber,
          'password': this.password,
          'address': this.address,
          'about': this.about,
          'namecompany': this.currentcompany,
          'profession': this.currentposition,
          'experience_year': this.yearexperience
          // 'birthdate': this.birthdate
        }
      })

      this.loading = true;
      var bodyFormData = {
        user: {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phonenumber: this.phonenumber,
          password: this.password,
          username: this.username,
          address: this.address,
          about: this.about,
          // birthdate: this.birthdate

          namecompany: this.currentcompany,
          profession: this.currentposition,
          experience_year: this.yearexperience
        }
      }
      this.loading = true;
      Drequest.api(`user.update?id=${this.$store.getters.getUserId}`)
        .data(bodyFormData)
        .raw((response) => {
          if (response.success === true) {
            this.loading = false;
            // alert(response.detail)
            this.loading = false;
            this.$store.commit("setUser", {
              id: response.user.id,
              email: response.user.email,
              firstname: response.user.firstname,
              lastname: response.user.lastname,
              phonenumber: response.user.phonenumber,
              username: response.user.username,
              // birthdate: response.user.birthdate,
              address: response.user.address,
              lastlogin: response.user.last_login,
            });

            this.currentcompany = response.user.namecompany
            this.currentposition = response.user.profession
            this.yearexperience = response.user.experience_year
            // this.$router.push({ name: 'user.account' });
          }
          else {
            alert(response.values.log)
            this.loading = false;
          }
        }).catch((err) => {
          this.loading = false;
        });
    },

    resetForm() {
      this.firstname = this.user.firstname
      this.lastname = this.user.lastname
      this.username = this.user.username
      // this.birthdate = this.user.birthdate
      this.address = this.user.address
      this.phonenumber = this.user.phonenumber
      this.email = this.user.email
      this.about = this.user.about
      this.currentcompany = this.user.namecompany
      this.currentposition = this.user.profession
      this.yearexperience = this.user.experience_year
    }
  },
  beforeMount() {
    Drequest.api(`lazyloading.user?dfilters=on&id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        // this.id = response.listEntity[0].id,
        this.email = response.listEntity[0].email,
          this.firstname = response.listEntity[0].firstname,
          this.lastname = response.listEntity[0].lastname,
          this.phonenumber = response.listEntity[0].phonenumber,
          this.username = response.listEntity[0].username,
          // birthdate = response.listEntity[0].birthdate,
          this.address = response.listEntity[0].address,
          this.about = response.listEntity[0].about,
          this.currentposition = response.listEntity[0].profession,
          this.currentcompany = response.listEntity[0].namecompany,
          this.yearexperience = response.listEntity[0].experience_year,
          this.lastlogin = response.listEntity[0].last_login,
          this.user = response.listEntity[0]
      }
    }).catch((err) => { });
  },
  setup(props) {
    // const status = ['Active', 'Inactive', 'Pending', 'Closed']
    var icons = {
      mdiAlertOutline,
      mdiCloudUploadOutline,
    }
    return {
      icons
    }
  },
}
</script>
