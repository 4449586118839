<template>

    <div>
        <v-dialog v-model="dialog" max-width="720" transition="dialog-bottom-transition">
            <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn color="secondaryAccent" class="secondary" outlined v-bind="attrs" v-on="on"
                    @click="btnDialogClicked">
                    New education
                </v-btn>
            </template> -->
            <div class="bg-white relative">
                <div class="flex items-start justify-between p-4 border-b">
                    <h3 class="text-xl font-semibold">
                        Add education
                    </h3>
                    <button type="button"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                        data-modal-toggle="product-modal">
                        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </button>
                </div>

                <div class="p-6 space-y-6">
                    <form action="#">
                        <div class="grid grid-cols-6 gap-6">
                            <div class="col-span-6 sm:col-span-6">
                                <label for="prosition name"
                                    class="text-sm font-medium text-gray-900 block mb-2">Education</label>
                                <input type="text" v-model="education.title" label="Diploma or class"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                                    placeholder="Diploma or class" required="">
                            </div>
                            <div class="col-span-6 sm:col-span-6">
                                <label for="brand" class="text-sm font-medium text-gray-900 block mb-2">School or
                                    University</label>
                                <input type="text" v-model="education.school" value="company_search.keywords.name"
                                    label="Enterprise"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                                    placeholder="School or University" required="">
                            </div>
                            <div class="col-span-6 sm:col-span-3">
                                <label for="startdate" class="text-sm font-medium text-gray-900 block mb-2">Started
                                    at</label>
                                <input type="date" v-model="education.started_at" label="Start date" neme="startdate"
                                    id="startdate"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                                    placeholder="" required="">
                            </div>

                            <div class="col-span-6 sm:col-span-3">
                                <label for="enddate" class="text-sm font-medium text-gray-900 block mb-2">End at</label>
                                <input type="date" v-model="education.end_at" label="End date" neme="enddate"
                                    id="enddate"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                                    placeholder="" required="">
                            </div>

                            <div class="col-span-full">
                                <label for="comment" class="text-sm font-medium text-gray-900 block mb-2">Add
                                    description
                                </label>
                                <textarea v-model="education.comment" label="comment about education" rows="6"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-4"
                                    placeholder="Others comments"></textarea>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="p-6 border-t border-gray-200 rounded-b">
                    <v-btn color="error" outlined class="mt-4 mr-4" type="reset" @click.prevent="resetForm">
                        Unsave
                    </v-btn>
                    <v-btn color="primary" class="mt-4" :loading="loading ? true : false"
                        @click.prevent="saveEducation">
                        Validate update
                    </v-btn>
                </div>
            </div>
        </v-dialog>

        <div class="bg-white rounded-lg mt-10">
            <div class="py-4 flex items-start justify-between cursor-pointer">
                <button
                    class="h-8 px-3 text-md font-bold text-blue-400 border border-blue-400 rounded-full hover:bg-blue-100"
                    @click="btnDialogClicked">Add education</button>
            </div>
            <div class="space-y-4">
                <div v-for="(item, ids) in usereducation" :key="ids">
                    <div class="py-4 flex items-start justify-between border-t">
                        <div class="flex items-start">
                            <img class="h-10 w-10" :src="require('@/assets/images/avatars/profil.jpg')">
                            <div class="ml-2 flex flex-col">
                                <div class="leading-snug text-md text-gray-900 font-bold">{{ item.diploma }}</div>
                                <div class="leading-snug text-md text-gray-600">{{
                                    item.school }}</div>
                                <div class="leading-snug text-xs text-gray-600">{{ item.startdate }} - {{
                                    item.enddate ? item.enddate : "Aujourd'hui" }}</div>
                                <div class="leading-snug text-md mt-2 text-gray-900">{{ item.comment }}</div>
                            </div>
                        </div>
                        <v-btn text small rounded-lg color="secondary" class="px-3 text-md border subsecondary"
                            @click="editEducation(item)">Edit</v-btn>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
    inject: {
        theme: {
            default: { isDark: false },
        },
    },
    data() {
        return {
            loading: false,
            about: '',
            oabout: '',
            dialog: false,
            education: {
                title: '',
                enterprise: null,
                enterprise_name: '',
                started_at: '',
                end_at: '',
                activity: '',
                comment: '',
                edit: false,
                keys: null
            },
            company_search: {
                datas: [],
                keywords: "",
                visibility: true
            },
            usereducation: []
        }
    },
    methods: {
        btnDialogClicked() {
            this.education.keys = null
            this.education.edit = false
            this.dialog = !this.dialog
            this.education.title = ''
            // this.education.enterprise_name = ''
            this.education.started_at = ''
            this.education.end_at = ''
            // this.education.activity = ''
            this.education.comment = ''
        },
        resetForm() {
            this.about = this.oabout
        },
        editEducation(value) {
            this.education.keys = value.id
            this.education.edit = true
            this.dialog = !this.dialog

            this.education.title = value.diploma
            this.education.school = value.school
            this.education.started_at = value.startdate
            this.education.end_at = value.enddate
            // this.education.activity = value.activity
            this.education.comment = value.comment
        },
        saveEducation() {

            // 'id' => $this->id,
            //         'diploma' => $this->diploma,
            //         'sartdate' => $this->sartdate,
            //         'enddate' => $this->enddate,
            //         'school' => $this->school,
            //         'comment' => $this->comment,
            //         'statut' => $this->statut,
            //         'user' => $this->user,
            this.loading = false;

            var reqaction = 'education.create'
            // var tmp_enterprise = this.company_search.datas.filter((item) => item.name.toLowerCase() == this.company_search.keywords.toLowerCase())
            var bodyFormData = {
                education: {
                    "user.id": this.$store.getters.getUser.id,
                    diploma: this.education.title,
                    "enterprise.id": null,
                    school: this.education.school,
                    startdate: this.education.started_at,
                    enddate: this.education.end_at,
                    // statut: this.education.end_at?1:0,
                    comment: this.education.comment,
                }
            }
            if (this.education.edit === true) {
                reqaction = `education.update?dfilter=on&id:eq=${this.education.keys}`
                this.education.edit = false
            }
            // console.log(bodyFormData)
            this.loading = true;
            Drequest.api(reqaction)
                .data(bodyFormData)
                .raw((response) => {
                    switch (response.success) {
                        case true:
                            this.loading = false;
                            // this.$router.push({ name: 'user.account' });
                            this.fetchEducation()
                            this.dialog = !this.dialog
                            this.education.edit = false
                            break;
                        default:
                            this.loading = false;
                            this.education.edit = false
                            break;
                    }
                }).catch((err) => {
                    this.loading = false;
                    this.education.edit = false
                });
        },
        filteredCompany() {
            if (this.company_search.keywords.length > 0) {
                this.company_search.visibility = true
                return this.company_search.datas.filter((item) => item.name.toLowerCase().includes(this.company_search.keywords.toLowerCase()))
            }
            else {
                this.education.enterprise_name = this.company_search.keywords
                this.company_search.visibility = false
                return []
            }
        },
        fetchEducation() {
            Drequest.api(`lazyloading.education?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
                if (response.success === true) {
                    this.usereducation = response.listEntity
                }
            }).catch((err) => { });
        }
    },
    beforeMount() {
        Drequest.api(`lazyloading.user?dfilters=on&id:eq=${this.$store.getters.getUser.id}`).get((response) => {
            if (response.success === true) {
                this.about = response.listEntity[0].about
                this.oabout = this.about
            }
        }).catch((err) => { });

        this.fetchEducation()
    },
    mounted() {
        Drequest.api(`lazyloading.enterprise`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((element, index) => {
                        this.company_search.datas.push({
                            name: element.name,
                            token: element.id
                        })

                    });
                }
            })
            .catch((err) => {
            });
    }
}
</script>